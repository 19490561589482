<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalCopyReservation.Title_CopyReservation')"
    modalSize="large"
    cssClassModalBody="is-hidden-overflow no-padding"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="buttonClickHandler"
    :hideFooterButtons="isSavingSuccess || !showInput"
    :disableSaveButton="!enableCopyButton"
    :showModal="showModal"
    :buttonLabelConfirm="!copyFinalized ? 'Copy' : 'Close'"
    @closeModal="onClickCancel"
    @modalBodySize="(val) => { modalBodySize = val }"
  >
    <template v-slot:content>
      <div class="slider-wrapper">
        <transition-group
          :name="back ? 'slideback' : 'slide'"
          tag="div"
        >
          <div
            v-if="showInput"
            :key="1"
            class="slideContent slideContent-dateSelector"
            :style="{height: modalBodySize.height + 'px'}"
          >
            <div
              v-if="reservation.MeetingtypeId === 1"
              class="columns is-multiline wrapper-dateSelector"
            >
              <div class="column">
                <v-date-picker
                  mode="single"
                  :isExpanded="true"
                  v-model="startDate"
                  :columns="$screens({ default: 1, md: 2 })"
                  :is-inline="true"
                  :is-required="true"
                  :min-date="minDate"
                  @dayclick="searchAvailability"
                  class="has-margin-bottom-x2"
                />

                <transition
                  name="fade"
                  mode="out-in"
                >
                  <ui-loader
                    key="loader"
                    v-if="isCheckingDate"
                  />

                  <div
                    key="result"
                    v-else
                  >
                    <Message
                      v-if="showContactTheLocation && meetingspaces.length"
                      class="is-info"
                    >
                      <div
                        v-html="$t('Components.Reservation.ModalCopyReservation.Message_OutsideOpeningHours')"
                        class="has-margin-bottom"
                      ></div>
                      <div v-if="locationManager && locationManager.Phone">
                        <strong v-text="$t('Views.Reservations.ReservationDetail.Label_Phone')"></strong> {{locationManager.Phone}}
                      </div>
                      <div v-if="locationManager && locationManager.Email">
                        <strong v-text="$t('Views.Reservations.ReservationDetail.Label_Email')"></strong> {{locationManager.Email}}
                      </div>
                    </Message>

                    <Message
                      v-if="!allSpacesAvailable && !showContactTheLocation && meetingspaces.length"
                      class="is-warning"
                    >
                      {{$t('Components.Reservation.ModalCopyReservation.Message_NotAllSpacesAvailable')}}
                    </Message>
                    <Message
                      v-if="message.length > 0"
                      class="is-danger"
                    >
                      {{ message }}
                    </Message>
                    <table
                      v-if="allSpacesAvailable"
                      class="table-spaces table is-fullwidth is-striped"
                    >
                      <tbody>
                        <!-- <tr class="has-text-vertical-middle">
                          <td v-text="$t('Components.Reservation.ModalCopyReservation.Table_CopyOptions')"></td>
                          <td>
                            <div class="tabs is-toggle is-small">
                              <ul>
                                <li :class="{ 'is-active': optionOptions === 0 }">
                                  <a @click="optionOptions = 0">
                                    <span v-text="$t('Components.Reservation.ModalCopyReservation.Radio_No')"></span>
                                  </a>
                                </li>
                                <li :class="{ 'is-active': optionOptions === 1 }">
                                  <a @click="optionOptions = 1">
                                    <span v-text="$t('Components.Reservation.ModalCopyReservation.Radio_Yes')"></span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr> -->
                        <tr>
                          <td v-text="$t('Components.Reservation.ModalCopyReservation.Table_MakePart')"></td>
                          <td>
                            <div class="tabs is-toggle is-small">
                              <ul>
                                <li :class="{ 'is-active': optionSet === 0 }">
                                  <a @click="optionSet = 0">
                                    <span v-text="$t('Components.Reservation.ModalCopyReservation.Radio_No')"></span>
                                  </a>
                                </li>
                                <li :class="{ 'is-active': optionSet === 1 }">
                                  <a @click="optionSet = 1">
                                    <span v-text="$t('Components.Reservation.ModalCopyReservation.Radio_Yes')"></span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                        <!-- <tr
                          class="has-text-vertical-middle"
                          v-if="reservation.VoucherId > 0"
                        >
                          <td>
                            <span
                              :class="{ 'has-text-grey-light': optionVoucher !== 0 }"
                              v-text=" $t('Components.Reservation.ModalCopyReservation.Table_ApplyVoucher')"
                            ></span>
                          </td>
                          <td>
                            <div class="tabs is-toggle is-small">
                              <ul>
                                <li :class="{ 'is-active': !applyVoucher }">
                                  <a @click="applyVoucher = false">
                                    <span v-text="$t('Components.Reservation.ModalCopyReservation.Radio_No')"></span>
                                  </a>
                                </li>
                                <li :class="{ 'is-active': applyVoucher }">
                                  <a @click="applyVoucher = true">
                                    <span v-text="$t('Components.Reservation.ModalCopyReservation.Radio_Yes')"></span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr> -->
                      </tbody>
                    </table>
                  </div>
                </transition>
              </div>

              <div class="column is-5">
                <Message
                  v-if="showSelectDateMessage"
                  class="is-info"
                >
                  {{ $t('Components.Reservation.ModalCopyReservation.Message_SelectDate') }}
                </Message>
                <div v-else>
                  <div
                    v-text="$tc('Components.Reservation.ModalCopyReservation.Title_MeetingRooms', meetingspaces.length).toLowerCase()"
                    class="title is-3"
                  ></div>
                  <div
                    v-for="(meetingspace) in meetingspaces"
                    :key="meetingspace.Id"
                    class="box box-space"
                  >
                    <span
                      v-if="meetingspace.IsAvailable"
                      class="icon is-success has-text-success"
                    >
                      <font-awesome-icon :icon="['fas', 'check']" />
                    </span>

                    <div
                      class="title is-5 is-marginless"
                      v-text="meetingspace.SpaceName"
                    ></div>
                    <div>
                      {{ $tc('Components.Reservation.ModalCopyReservation.Label_Seats', meetingspace.Seats) }} ({{ $options.filters.getSettingName(meetingspace.SettingId) }})
                    </div>
                    <div v-if="meetingspace.IsAvailable">
                      <div>{{ $t('Components.Reservation.ModalCopyReservation.Text_PricePerSeat', 
                      { price: $options.filters.toCurrency(meetingspace.PricePerSeat) }) }}</div>
                      <div>{{ $t('Components.Reservation.ModalCopyReservation.Text_PriceTotal', 
                      { price: $options.filters.toCurrency(meetingspace.PriceTotal) }) }}</div>
                    </div>
                    <div
                      v-if="!meetingspace.IsAvailable"
                      class="notification is-warning"
                    >
                      <ul>
                        <li
                          v-if="showWarning(meetingspace.CheckString, 0)"
                          v-text="$t('Components.Reservation.ModalCopyReservation.List_LocationIsClosedOnThatDay')"
                        ></li>
                        <li
                          v-if="showWarning(meetingspace.CheckString, 1)"
                          v-text="$t('Components.Reservation.ModalCopyReservation.List_LocationIsClosed')"
                        ></li>
                        <li v-if="showWarning(meetingspace.CheckString, 2)">
                          <div class="wrapper">
                            <div
                              class="has-margin-bottom-half"
                              v-text="$t('Components.Reservation.ModalCopyReservation.List_SpaceIsNotAvailable')"
                            ></div>
                            <a
                              v-if="!meetingspace.IsAvailable"
                              @click="setShowSpaceAlternatives(meetingspace)"
                              class="button is-small is-success"
                              v-text="$t('Components.Reservation.ModalCopyReservation.Button_ShowAlternatives')"
                            ></a>
                          </div>
                        </li>
                        <li
                          v-if="showWarning(meetingspace.CheckString, 3)"
                          v-text="$t('Components.Reservation.ModalCopyReservation.List_MinimumHoursNotMet')"
                        ></li>
                        <li
                          v-if="showWarning(meetingspace.CheckString, 4)"
                          v-text="$t('Components.Reservation.ModalCopyReservation.List_NumberOfSeats')"
                        ></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="reservation.MeetingtypeId === 3"></div>
          </div>

          <!-- Show alternatives -->
          <div
            v-if="showSpaceAlternatives"
            key="alternativeSpaces"
            class="slideContent-alternatives slideContent"
            :style="{height: modalBodySize.height + 'px'}"
          >
            <div class="backbar">
              <a
                class="has-icon"
                @click="setShowInput"
              >
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </span>
                <span v-text="$t('Form.Control.Back')"></span>
              </a>
              <hr />
            </div>

            <div class="columns-alternatives columns is-gapless is-mobile is-vcentered">
              <div class="column is-two-fifths">
                <div class="has-padding">
                  <ReservationSpaceCard :reservationSpace="selectedSpace" />
                </div>
              </div>
              <div class="column has-text-centered">
                <font-awesome-icon :icon="['fas', 'arrow-right']" />
              </div>
              <div
                class="column column-alternatives is-two-fifths"
                style=""
              >
                <ui-loader v-if="isLoadingAlternatives" />

                <AvailableLocation
                  v-else
                  v-for="location in availablity.Locations"
                  :key="location.Id"
                  :availableLocation="location"
                  :meetingtypeId="reservation.MeetingtypeId"
                  :meetingSpaces="meetingspaces"
                  viewType="list-card"
                  class="list-availableSpaces"
                />
              </div>
            </div>
          </div>

          <!-- Confirmation -->
          <div
            v-if="showConfirmation"
            :key="3"
            class="slideContent slideContent-confirmation"
            :style="{height: modalBodySize.height + 'px'}"
          >
            <h1 v-text="$t('Components.Reservation.ModalCopyReservation.Confirmation_New-reservation-created')"></h1>
            <h3 v-if="newReservation">{{ newReservation.Id }}</h3>
          </div>
        </transition-group>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { EventBus } from '@/eventBus/event-bus'
import { mapState, mapMutations, mapActions } from 'vuex'
import format from 'date-fns/format'

import availabilityProvider from '../../providers/availability'
import reservationProvider from '../../providers/reservation'
// import voucherProvider from '../../providers/voucher'

import AvailableLocation from './Availability/AvailableLocation'
import ReservationSpaceCard from './ReservationSpaceCard'
import Message from '@/components/UI/Message'

import VCalendar from 'v-calendar'
Vue.use(VCalendar, {})

export default {
  components: {
    AvailableLocation,
    Message,
    ReservationSpaceCard,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      applyVoucher: false,
      availablity: {},
      back: false,
      copyFinalized: false,
      date: new Date().getTime(),
      dataFormat: 'd MMMM YYYY',
      startDate: null,
      endDate: null,
      fieldId: new Date().getTime(),
      isCheckingDate: false,
      isLoadingAlternatives: false,
      isSaving: false,
      isSavingSuccess: false,
      meetingspaces: [],
      message: '',
      minDate: new Date(),
      optionOptions: 1,
      optionSet: 0,
      copyOptionNotes: 1,
      copyComments: 1,
      optionVoucher: 0,
      newReservation: null,
      showSpaceAlternatives: false,
      selectedSpace: null,
      showConfirmation: false,
      showInput: true,
      dateChangeCounter: 0,
      // voucher: null,
      modalBodySize: {
        width: 0,
        height: 0
      },
      startDateISO: '',
      endDateISO: '',
      showSelectDateMessage: true
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation', 'locationManager']),

    showContactTheLocation() {
      for (let i = 0; i < this.meetingspaces.length; i++) {
        let ms = this.meetingspaces[i]

        if (this.showWarning(ms.CheckString, 0) === true || this.showWarning(ms.CheckString, 1) === true) {
          return true
        }
      }

      return false
    },

    allSpacesAvailable() {
      let allAvailable = false
      let checkCounter = 0

      if (this.meetingspaces && this.meetingspaces.length > 0) {
        for (let i = 0; i < this.meetingspaces.length; i++) {
          let ms = this.meetingspaces[i]

          if (ms.IsAvailable === true) {
            checkCounter = checkCounter + 1
          }
        }
      }

      if (checkCounter > 0 && checkCounter === this.meetingspaces.length) {
        allAvailable = true
      }

      return allAvailable
    },

    enableCopyButton() {
      return this.allSpacesAvailable
    },
  },

  created() {
    this.startDate = this.$options.filters.parseIsoDateStringToDate(this.reservation.StartDate)
    this.endDate = this.$options.filters.parseIsoDateStringToDate(this.reservation.EndDate)

    this.startDateISO = this.$options.filters.dateObjectIsoDateString(this.reservation.StartDate)
    this.endDateISO = this.$options.filters.dateObjectIsoDateString(this.reservation.EndDate)
    if (this.reservation.MeetingtypeId !== 3) {
      this.endDate = this.reservation.StartDate
      this.endDateISO = this.$options.filters.dateObjectIsoDateString(this.reservation.StartDate)
    }

    if (this.reservation.VoucherId > 0) {
      this.applyVoucher = this.reservation.VoucherId > 0
      this.getVoucher()
    }
  },

  mounted() {
    this.openModal(this.$el)
    EventBus.$on('spaceSelected', (space) => {
      this.updateSelectedSpaceWithAlternative(space)
    })
  },

  beforeDestroy() {
    EventBus.$off('spaceSelected')
    this.closeModal(this.$el)
  },

  methods: {
    ...mapActions('modalStore', ['openModal', 'closeModal']),
    ...mapMutations('reservationStore', ['setReservation']),

    removeFocusFromInput(elm) {
      elm.target.blur()
    },

    searchAvailability(val) {
      if (val.isDisabled !== false) { return }
      this.showSelectDateMessage = false
      this.startDate = val.date
      this.startDateISO = this.$options.filters.dateObjectIsoDateString(val.date)
      if (this.reservation.MeetingtypeId !== 3) {
        this.endDate = val.date
        this.endDateISO = this.$options.filters.dateObjectIsoDateString(val.date)
      }
      this.checkAvailability()
    },

    formatDates(startDate = '', endDate = '') {
      let formattedDates = ''
      if (startDate) {
        formattedDates = format(startDate, this.dataFormat)
      }
      return formattedDates
    },

    getVoucher() {
      let self = this
      // voucherProvider.methods
      //   .getVoucherById(self.reservation.VoucherId)
      //   .then((response) => {
      //     if (response.status === 200) {
      //       self.voucher = response.data
      //     }
      //   })
    },

    checkAvailability() {
      this.isCheckingDate = true
      this.meetingspaces = []
      this.showSpaceAlternatives = false

      this.optionOptions = 0
      this.optionSet = 0
      this.optionVoucher = 0

      if (this.reservation.MeetingtypeId < 3) {
        this.endDate = this.startDate
      }

      availabilityProvider.methods
        .copyReservationCheckAvailability(
          this.reservation.Id,
          this.startDateISO,
          this.endDateISO
        )
        .then((response) => {
          this.meetingspaces = response.data
        })
        .finally(() => {
          this.isCheckingDate = false
        })
    },

    cancelCopy() {
      this.$emit('cancelCopy')
    },

    buttonClickHandler() {
      if (this.copyFinalized) {
        this.onClickCancel()
      } else {
        this.copyReservation()
      }
    },

    copyReservation() {
      let self = this
      self.isSaving = true

      let criteria = {
        ReservationId: self.reservation.Id,
        StartDate: self.startDateISO,
        EndDate: self.endDateISO,
        ReservationSpaces: self.meetingspaces,
        InSet: self.optionSet === 1,
        CopyOptions: true,
        CopyNotes: self.copyOptionNotes === 1,
        CopyComments: self.copyComments === 1,
        ApplyVoucher: self.applyVoucher,
      }

      reservationProvider.methods
        .copyReservation(criteria)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.StatusCode === 1) {
              self.isSavingSuccess = true

              self.copyFinalized = true
              self.newReservation = response.data.Result

              if (self.optionSet === 1) {
                if (self.reservation.SetId === 0) {
                  self.reservation.SetId = self.reservation.Id

                  self.reservation.ReservationSetIds.push({
                    Id: self.reservation.Id,
                    Date: self.reservation.StartDate,
                  })
                }

                self.reservation.ReservationSetIds.push({
                  Id: self.newReservation.Id,
                  Date: self.newReservation.StartDate
                })

                self.setReservation(self.reservation)
              }

              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: this.$t(
                  'Components.Reservation.ModalCopyReservation.Confirmation_NewReservationCreated'
                ),
              })

              self.goToReservation(self.newReservation.Key)

              self.onClickCancel()
            } else {
              self.message = response.data.Message
            }
          }
        })
        .finally(() => {
          self.isSaving = false
        })
    },

    setShowSpaceAlternatives(space) {
      let self = this

      if (!self.isLoadingAlternatives) {
        self.availablity = {}
        self.isLoadingAlternatives = true

        self.back = false

        self.showInput = false
        self.selectedSpace = space
        self.selectedSpace.LocationId = this.reservation.LocationId
        self.showSpaceAlternatives = true

        let countryId = 0
        let channelId = self.reservation.ChannelId
        let searchterm = ''
        let settingId = 0

        availabilityProvider.methods
          .getAvailability(
            this.startDateISO,
            this.endDateISO,
            channelId,
            countryId,
            space.LocationId,
            searchterm,
            space.MeetingTypeId,
            space.Seats,
            space.StartMinutes,
            space.EndMinutes,
            settingId
          )
          .then((response) => {
            self.availablity = response.data
          })
          .finally(() => {
            self.isLoadingAlternatives = false
          })
      }
    },

    updateSelectedSpaceWithAlternative(space) {
      let self = this

      let spaceIndex = self.meetingspaces.findIndex(
        (m) => m.Id === self.selectedSpace.Id
      )
      if (spaceIndex > -1) {
        let newSpace = self.meetingspaces[spaceIndex]

        newSpace.SpaceId = space.SpaceId
        newSpace.SpaceName = space.SpaceName
        newSpace.SpaceImage = space.SpaceImage
        newSpace.StartDate = space.StartDate
        newSpace.StartMinutes = space.StartMinutes
        newSpace.EndDate = space.EndDate
        newSpace.EndMinutes = space.EndMinutes
        newSpace.SettingId = space.SettingId
        newSpace.NewPrice = space.PricePerSeat
        newSpace.NewPricePerSeat = space.PricePerSeat
        newSpace.NewPriceTotal = space.PriceTotal
        newSpace.PricePerSeat = space.PricePerSeat
        newSpace.PriceTotal = space.PriceTotal
        newSpace.Crc = space.Crc
        newSpace.Hash = space.Hash
        newSpace.CheckString = '1#1#1#1#1'

        Vue.set(self.meetingspaces, spaceIndex, newSpace)

        self.ignoreWarning(self.meetingspaces[spaceIndex], 2)

        self.setShowInput()
      }
    },

    showWarning(checkString, index) {
      let checks = checkString.split('#')

      return Number(checks[index]) === 0
    },

    ignoreWarning(meetingspace, index) {
      let checks = meetingspace.CheckString.split('#')
      checks[index] = 1

      let checkIndex = checks.findIndex((c) => c === 0)
      if (checkIndex === -1) {
        meetingspace.IsAvailable = true
      }

      meetingspace.CheckString = checks.join('#')
    },

    setShowInput() {
      this.back = true
      this.showInput = true
      this.showSpaceAlternatives = false
      this.availablity = {}
      this.selectedSpace = null
    },

    setShowConfirmation() {
      this.showInput = false
      this.showSpaceAlternatives = false
      this.showConfirmation = true
    },

    goToReservation(reservationKey) {
      this.$router.push({
        name: 'reservation-detail',
        params: { reservationKey: reservationKey },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.slider-wrapper {
  overflow: hidden;
  height: 80vh;
  max-height: 600px;

  .slideContent {
    height: 100%;
    overflow: hidden;

    .notification {
      padding: 10px;
    }

    &.slideContent-dateSelector {
      overflow: auto;

      .box-space {
        position: relative;
        .icon {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    &.slideContent-alternatives {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
      .backbar {
        flex-grow: 0;
        flex-shrink: 0;
      }
      .columns-alternatives {
        height: 100%;
        overflow: hidden;

        .column-alternatives {
          overflow: auto;
          overflow-x: hidden;
          height: 100%;

          .list-availableSpaces {
            padding: 0 3px;
          }
        }
      }
    }
  }
}
</style>
